import React from "react"
import { Link } from "gatsby"
import Layout from "../components/common/layout/layout"
import { Container } from "../components/global"
import styled from "styled-components"

const PrivacyPolicy = () => (
  <Layout>
    <Container>
      <HeaderWrapper>
        <Link to="/">
          <Button>Back to homepage</Button>
        </Link>
      </HeaderWrapper>
      <Title>Policy Privacy</Title>
      <Paragraph>
        LT Tiles and its related and affiliated corporations recognize that your right to privacy is fundamental. We appreciate and respect your interest in maintaining the privacy of information which you provide while registering at our sales offices or visiting our websites. Our privacy policy is designed to respect your personal information by incorporating and complying to the applicable legislation to the highest standards. You have our assurance that personal information gathered by our organization will be kept in confidence. We commit to not selling, trading or otherwise share the information you provide to our organization. We collect, use and disclose personal information only for the limited purpose of recording your interest in our various development products and services. We do so in the belief that we will be able to enhance customer experience by properly collecting the various wants and needs of our cliental. If at anytime you wish to have your personal information removed from our records, please contact us Immediately. Private information will only be released to third parties when permitted and/or required by the applicable law.
      </Paragraph>
    </Container>
  </Layout>
)

const Paragraph = styled.section`
  font-size: 1.1rem;
  padding: 0;
  text-align: justify;
`

const Title = styled.h1`
display: flex;
  justify-content: center;
  text-align: center;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Button = styled.button`

  color: ${(props) => props.theme.color.primary};
  padding: 1em;
  margin-left: 8px;
  border-radius: 5px;
  text-decoration: none;
  background-color: #ffd502;
  border: 3px solid black;
  font-weight: bolder;
  margin-left: 2.5em;
  margin-top: 3em;

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

export default PrivacyPolicy
